import React from 'react'
import { ArrowContainer, ArrowUp } from './ReturnElements';


const ReturnArrow = ({scrollNav, toggleHome}) => {
    

    return (
        <ArrowContainer>
            <ArrowUp onClick={toggleHome} scrollNav={scrollNav}/>
        </ArrowContainer>
    )
}

export default ReturnArrow
