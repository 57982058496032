import styled from "styled-components";


export const ContentWrapper = styled.div`
    display: flex;
    width: 80%;
    flex-direction: column;

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        width: 90%;
    }
`;

export const TextWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 4rem;
    
`;


export const SubTitle = styled.h3`
    letter-spacing: 0.3rem;
    font-size: 1.5rem;
`;

export const SectionGrid =styled.div`
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        display: flex;
        justify-items: center;
        flex-direction: column;
    }
`;

export const Section1 = styled.div`
    width: 80%;
    display: flex;

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        align-self: center;
    }
`;

export const Section2 = styled.div`
    width: 80%;
    display: flex;

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        padding-top: 2rem;
        align-self: center;
    }
`;

export const Section3 = styled.div`
    width: 80%;
    display: flex;

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        padding-top: 2rem;
        align-self: center;
    }
`;


export const ContentGraph = styled.div`

`;

export const Graph = styled.div`
/* CHANGE THIS */
`;

export const CardContainer = styled.div`
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        display: flex;
        justify-items: center;
        flex-direction: column;
    }
`;

export const SlideBox = styled.div`
    box-shadow: 0 3px 8px 0 rgba(0,0,0,0.2);
    width: 20rem;
    border-radius: 1rem;
    padding: 1rem 0 2rem 0;
    margin: 1rem;
    display: flex;
    justify-content: center;
`;

export const SlideContent = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: center;
`;

export const SlideTitle = styled.p`
    font-family: 'Inter', sans-serif;
    font-weight: bold;
`;

