import styled from "styled-components";
import Background from "../../images/Background.jpg";

export const HeroContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* min-height: 800px; */
    z-index: 1;
    overflow: hidden;
    background-image: url(${Background});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
`;

export const HeroContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
     */
`;

export const HeroH1=styled.h1`
    color: ${({ theme }) => theme.textLight};
    font-family: 'Bebas Neue', cursive;
    font-size: 4rem;
    text-align: center;
    display: inline;
    letter-spacing: 1.5rem;
    margin-right: -1.5rem;
    font-weight: 400;
    
    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        font-size: 40px;
    }

    @media screen and (max-width: ${({ theme }) => theme.mobile}) {
        font-size: 32px;
    }
`;

export const HeroP = styled.p`
    margin-top: 24px;
    color: ${({ theme }) => theme.textLight};
    font-size: 24px;
    text-align: center;
    letter-spacing: 0.3rem;
    max-width: 600px;

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        font-size: 24px;
    }

    @media screen and (max-width: ${({ theme }) => theme.mobile}) {
        font-size: 18px;
    }
`;