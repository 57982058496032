import styled from "styled-components";

export const GridWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 80px;
    border-bottom: 1px solid rgba(0,0,0,0.3);
`;

export const TitleWrapper = styled.div`
    position: relative;
    display: flex;
    padding-top: 1rem;
`;

export const GridTitle = styled.h2`
    letter-spacing: 0.3rem;
    font-size: 2rem;
`;

export const Grid =styled.div`
    display: grid;
    width: 80%;
    padding-top: 2rem;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: auto;
    grid-gap: 1rem;
    padding-bottom: 1.5rem;

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        grid-template-columns: 100%;
    }
`;

