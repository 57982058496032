import React from 'react';
import {
    SidebarContainer,
    Icon,
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarLink,
    SideBtnWrap, 
    SidebarRoute
} from './SidebarElements';

const Sidebar = ({isOpen, toggle}) => {
    
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to='about' onClick={toggle}>
                        About
                    </SidebarLink>
                    <SidebarLink to='services' onClick={toggle}>
                        How it Works
                    </SidebarLink>
                    <SidebarRoute to='/contributor' onClick={toggle}>
                        Contributors
                    </SidebarRoute>
                    {/* <SidebarLink to='contributors' onClick={toggle}>
                       Contracts
                    </SidebarLink> */}
                </SidebarMenu>
                <SideBtnWrap>
                    <SidebarRoute to='/signin'>Sign In</SidebarRoute>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar