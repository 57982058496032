import styled from "styled-components";

export const ProfileWrapper = styled.div`
    grid-column: ${({rowSlot}) => (rowSlot ? rowSlot : 4)};
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-left: 0.5rem; 

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        grid-column: 1;
    }
`;

export const ContributorPhoto = styled.img`
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1rem;
    min-width: 60%;
`;

export const Title = styled.h2`
    letter-spacing: 0.3rem;
    padding-top: 0.5rem;
    font-size: 1rem; 
`;

export const Description = styled.a`
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
    padding-bottom: 1rem;
    text-decoration: none;
    color: #000;
    cursor: pointer;

    &:hover {
        color: ${({theme}) => theme.secondaryHover};
        transition: 0.3s ease-out;
    }
`;