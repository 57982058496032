import styled from "styled-components";

export const ContentSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-left: 0.5rem;
`;

export const VideoWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 1rem;
`;

export const Title = styled.h2`
    letter-spacing: 0.5rem;
    font-size: 1.5rem;
    text-align: center;
`;

