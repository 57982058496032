export const theme = {
    /* Div Themes */
    backgroundLight: '#FFFFFF',
    backgroundDark: '#04040f',
  /* Button themes */
    primaryDark: 'rgba(0,0,0,1)',
    primaryLight: '#62a3c4',
    primaryHover: '#1a2226',
    secondaryHover: '#c5d6e2',
    borderLight: '#c5d6e2',
    borderDark: '#000',
  /* Text themes */
    textDark: '#000000',
    textLight: '#FFFFFF',
  /* Screen Sizing styles */
    mobile: '480px',
    tablet: '769px',
}