import React, { useState, useEffect } from 'react'
import { animateScroll as scroll } from 'react-scroll';
import { ThemeProvider } from 'styled-components'
import HeroSection from '../components/HeroSection'
import HomeSlide from '../components/HomeSlide'
import { SlideOne, SlideThree, SlideTwo, SlideFour, SlideFive } from '../components/Data'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import { theme } from '../theme'
import ReturnArrow from '../components/ReturnArrow'
import FAQ from '../components/FAQ';




const Home = ({isOpen, toggleSidebar}) => {
    const [scrollNav, setScrollNav] = useState(false);


    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop();
    }


    return (
       <ThemeProvider theme={theme}>
           <Navbar toggle={toggleSidebar} scrollNav={scrollNav} toggleHome={toggleHome}/>
           <Sidebar isOpen={isOpen} toggle={toggleSidebar}/>
           <ReturnArrow scrollNav={scrollNav} toggleHome={toggleHome}/>
           <HeroSection />
           <HomeSlide {...SlideOne} />
           <HomeSlide {...SlideTwo} />
           <HomeSlide {...SlideThree } />
           <HomeSlide {...SlideFour } />
           <HomeSlide {...SlideFive } />
           <FAQ />
           <Footer />
       </ThemeProvider>
    )
}

export default Home
