import React from 'react'
import {  ContentText } from '../SubmissionDetails/SubmissionElements';
import { ContentSection,
         Title,
         VideoWrapper, } from './TutorialContentElements';

const TutorialContent = ({titleText,
                         contentText,
                         videoUrl,
                         videoTitle}) => {
  return (
    <ContentSection >
            <Title>{titleText}</Title>
            <VideoWrapper>
            <iframe
                width="560" 
                height="315" 
                src={videoUrl} 
                title={videoTitle}
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen>
            </iframe>{" "}
            </VideoWrapper>
            <ContentText>{contentText}</ContentText>
        </ContentSection>
  )
}

export default TutorialContent