import React from 'react';
import {
    SidebarContainer,
    Icon,
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarLink,
    SidebarRoute
} from './SidebarTwoElements';

const SidebarTwo = ({isOpen, toggle}) => {
    
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to='/' exact onClick={toggle}>
                        Home
                    </SidebarLink>
                    <SidebarLink to='/contact' onClick={toggle}>
                        Contact
                    </SidebarLink>
                    <SidebarRoute to='/contributor' onClick={toggle}>
                        Contributors
                    </SidebarRoute>
                    {/* <SidebarLink to='contributors' onClick={toggle}>
                       Contracts
                    </SidebarLink> */}
                </SidebarMenu>
                {/* <SideBtnWrap>
                    <SidebarRoute to='/signin'>Sign In</SidebarRoute>
                </SideBtnWrap> */}
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default SidebarTwo