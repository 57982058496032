import React from 'react'
import { SlideContainer,
         SlideWrapper,
         SlideRow,
         Column1,
         Column2,
         TextWrapper,
         TextColumn,
         TopLine,
         ParagraphText,
         ImgWrap,
         Img } from './SlideElements';

const HomeSlide = ({
    id,
    header,
    description1,
    description2,
    description3,
    imgStart,
    img,
    alt
}) => {
    return (
        <SlideContainer id={id}>
            <SlideWrapper>
                <SlideRow imgStart={imgStart}>
                    <Column1>
                        <TextWrapper>
                            <TextColumn>
                                <TopLine>{header}</TopLine>
                                <ParagraphText>{description1}</ParagraphText>
                                <ParagraphText>{description2}</ParagraphText>
                                <ParagraphText>{description3}</ParagraphText>
                            </TextColumn>
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <Img src={img} alt={alt} />
                        </ImgWrap>
                    </Column2>
                </SlideRow>
            </SlideWrapper>
        </SlideContainer>
    )
}

export default HomeSlide
