import React from 'react'
import { ElementWrapper,
         TitleWrapper,
         Title,
         ContentSection,
         ContentText } from '../SubmissionDetails/SubmissionElements';       
import { ContentWrapper,
         TextWrapper, 
         SubTitle,
         SectionGrid,
         Section1,
         Section2,
         Section3,
         ContentGraph,
         Graph,
         CardContainer,
         SlideBox,
         SlideContent,
         SlideTitle } from './EarningsElements';

const Earnings = () => {
    return (
        <ElementWrapper>
            <TitleWrapper>
                <Title>How Much Can My Media Earn Over Time?</Title>
            </TitleWrapper>
            <ContentWrapper>
                <TextWrapper>
                    <ContentSection>
                        <SubTitle>Starting Out</SubTitle>
                        <SectionGrid>
                            <Section1>
                                <ContentText>When you first get started, it's key to keep submitting regularily. Earning passive income from your media is like watering a plant and watching it grow. 
                            The more you nurture it the more it will succeed. </ContentText>
                            </Section1>
                            <Section2>
                                <ContentText>Contributors who submit 50-100 images and/or video monthly are the most successful. Or if you have a tight schedule, compiling these submissions into larger 3 month allotments is also a good way to build your collection.</ContentText>
                            </Section2>
                            <Section3>
                                <ContentText>You might have a collection of media already, this is a great head start! You'll start earning more at a faster rate, though it's still important to submit regularily to ensure revenue growth.</ContentText>
                            </Section3>
                        </SectionGrid>
                    </ContentSection>
                    <ContentGraph>
                        <Graph />
                    </ContentGraph>
                </TextWrapper>
                <TextWrapper>
                    <ContentSection>
                        <SubTitle>Whether you're new to submitting media or you have prior experience, you might be wondering how to maximize your earnings.</SubTitle>
                        <ContentText>Here are some examples from real contributors illustrating different submission styles and the growth in earnings that can be expected from them:</ContentText>
                        <ContentText></ContentText>
                        <CardContainer>
                            <SlideBox>
                                <SlideContent>
                                    <SlideTitle>Steady Submissions</SlideTitle>
                                    <ContentText>2016: $385.68</ContentText>
                                    <ContentText>2017: $7,258.09</ContentText>
                                    <ContentText>2018: $16,715.80</ContentText>
                                    <ContentText>2019: $27,660.48</ContentText>
                                    <ContentText>2020: $33,974.33</ContentText>
                                </SlideContent>
                            </SlideBox>
                            <SlideBox>
                                <SlideContent>
                                    <SlideTitle>Infrequent Submissions</SlideTitle>
                                    <ContentText>Example Text</ContentText>
                                </SlideContent>
                            </SlideBox>
                            <SlideBox>
                                <SlideContent>
                                    <SlideTitle>Large Initial Submission infrequent long term submissions</SlideTitle>
                                    <ContentText>Example Text</ContentText>
                                </SlideContent>
                            </SlideBox>
                        </CardContainer>
                    </ContentSection>
                    <ContentGraph>
                        <Graph></Graph>
                    </ContentGraph>
                </TextWrapper>
            </ContentWrapper>
        </ElementWrapper>
    )
}

export default Earnings
