import React from 'react'
import { ProfileWrapper,
         ContributorPhoto,
         Title,
         Description } from './ContributorContentElements'

const ContributorContent = ({ rowSlot, 
                              photo, 
                              alt, 
                              contribName, 
                              contribDescription }) => {
    return (
        <ProfileWrapper rowSlot={rowSlot}>
            <ContributorPhoto src={photo} alt={alt} />
            <Title>{contribName}</Title>
            <Description href={contribDescription} target='_blank' aria-label='Contributor Info'>{contribDescription}</Description> 
        </ProfileWrapper>
    )
}

export default ContributorContent
