import styled from 'styled-components';
import { BsArrowBarUp } from 'react-icons/bs';

export const ArrowContainer = styled.div`
    z-index: 5;
    position: fixed;
    right: 4%;
    bottom: 10%;

    @media screen and (max-width: ${({theme}) => theme.tablet}){
        display: none;
    }
`;

export const ArrowUp = styled(BsArrowBarUp)`
    width: auto;
    height: 50px;
    color: #000;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    display: ${({scrollNav}) => (scrollNav ? 'inline' : 'none')};
    

    &:hover {
        color: #666363;
        transition: 0.2s ease-in-out;
    }
`;