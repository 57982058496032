import styled from "styled-components";

export const ContactWrapper = styled.div`
    display: flex; 
    justify-content: center;
    /* max-width: 100%; */
    width: 100%;
    margin-top: 80px;
    
`;

export const ContactContent = styled.div`
    display: grid;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    min-height: 800px;
    grid-template-areas: 'col1 col2';
`;

export const Column1 = styled.div`
    grid-area: col1;
    display: flex;
    align-items: center;
    justify-content: right;
    border-right: 1px solid rgba(0,0,0, 0.2);
`;

export const Column2 = styled.div`
    grid-area: col2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
`;

export const Title = styled.h3`
   padding-right: 1.5rem;
   letter-spacing: 0.3rem;
`;

export const Subtext = styled.span`
    padding-top: 0.5rem;
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
    font-style: italic;

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        padding-left: 1rem;
    }
`;